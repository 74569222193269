import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171')
];

export const server_loads = [0,2,3,14,15,16,17,18,8,9,10,11,12,13];

export const dictionary = {
		"/(landing)": [~19,[2]],
		"/auth/error": [122],
		"/(legal)/cookies": [20,[3]],
		"/(public)/dana": [~23,[4]],
		"/(public)/directorio-info": [26,[4]],
		"/(public)/directorio": [~24,[4]],
		"/(public)/directorio/patient-form": [~25,[4]],
		"/error": [123],
		"/error/no-js": [124],
		"/(public)/invites/public-page/[id]": [~27,[4]],
		"/(public)/links": [~28,[4]],
		"/(public)/login": [~29,[4]],
		"/(public)/logout": [30,[4]],
		"/(public)/malamente": [~31,[5]],
		"/(public)/malamente/new": [~32,[5]],
		"/onboarding/clinica": [~133,[14]],
		"/onboarding/new-team-page": [~135,[14]],
		"/onboarding/new-team": [~134,[14]],
		"/onboarding/paciente": [~136,[14]],
		"/onboarding/[teamId]/completo": [~129,[14]],
		"/onboarding/[teamId]/locations": [~130,[14]],
		"/onboarding/[teamId]/owner": [~131,[14]],
		"/onboarding/[teamId]/plan": [~132,[14]],
		"/onboarding/[teamId]/[professionalId]": [~125,[14]],
		"/onboarding/[teamId]/[professionalId]/end": [~126,[14]],
		"/onboarding/[teamId]/[professionalId]/invoicing": [~127,[14]],
		"/onboarding/[teamId]/[professionalId]/key": [~128,[14]],
		"/paciente": [~137,[15]],
		"/paciente/[patientId]/documents/[docId]": [~138,[15]],
		"/paciente/[patientId]/settings": [~139,[15]],
		"/paddle/pay": [140],
		"/(legal)/politica-privacidad": [21,[3]],
		"/(public)/redirect-home": [~33,[4]],
		"/(public)/registro": [~34,[6]],
		"/(public)/registro/clinica": [~35,[6]],
		"/(public)/registro/directorio": [~36,[6]],
		"/(public)/registro/paciente": [~37,[6]],
		"/(public)/registro/paciente/[inviteCode]": [~38,[6]],
		"/(public)/registro/paciente/[inviteCode]/completo": [~39,[6]],
		"/(public)/registro/profesional": [~40,[6]],
		"/(public)/registro/profesional/[inviteCode]": [~41,[6]],
		"/(public)/registro/profesional/[inviteCode]/completo": [~42,[6]],
		"/(public)/registro/verificar": [~43,[6]],
		"/(public)/reset-password": [~44,[4]],
		"/superadmin": [141,[16]],
		"/superadmin/links": [~142,[16]],
		"/superadmin/locations": [~143,[16]],
		"/superadmin/public-pages": [~144,[16]],
		"/superadmin/public-pages/create": [~145,[16]],
		"/superadmin/testimonials": [~146,[16]],
		"/superadmin/testimonials/new": [~147,[16]],
		"/(legal)/terminos-y-condiciones": [22,[3]],
		"/test/availability": [148,[17]],
		"/test/cal": [149,[17]],
		"/test/docuseal": [~150,[17]],
		"/test/dt": [151,[17]],
		"/test/encryption": [152,[17]],
		"/test/error": [~153,[17]],
		"/test/error/full": [~154,[17]],
		"/test/error/unexpected": [~155,[17]],
		"/test/geocode": [~156,[17]],
		"/test/hubspot": [157,[17]],
		"/test/invites": [158,[17]],
		"/test/invoice-number": [159,[17]],
		"/test/navigation/a": [160,[17,18]],
		"/test/navigation/b": [161,[17,18]],
		"/test/number": [162,[17]],
		"/test/onboarding": [~163,[17]],
		"/test/onboarding/set": [~164,[17]],
		"/test/redirect/a": [165,[17]],
		"/test/redirect/b": [166,[17]],
		"/test/search": [~167,[17]],
		"/test/slots": [168,[17]],
		"/test/sms": [169,[17]],
		"/test/supabase": [~170,[17]],
		"/test/transactions": [~171,[17]],
		"/(public)/validate-invite": [45,[4]],
		"/[publicPage]": [~46,[7]],
		"/[team]/admin": [~54,[8,9]],
		"/[team]/admin/analitica": [55,[8,9]],
		"/[team]/admin/calendario": [56,[8,9]],
		"/[team]/admin/configuracion": [~57,[8,9]],
		"/[team]/admin/configuracion/documentos": [~58,[8,9]],
		"/[team]/admin/configuracion/documentos/[id]": [~59,[8,9]],
		"/[team]/admin/configuracion/eholo": [~60,[8,9]],
		"/[team]/admin/configuracion/emails": [~61,[8,9]],
		"/[team]/admin/configuracion/emails/[emailType]": [~62,[8,9]],
		"/[team]/admin/configuracion/finance": [~63,[8,9]],
		"/[team]/admin/configuracion/finance/[legalEntityId]": [~64,[8,9]],
		"/[team]/admin/configuracion/import-v2": [~65,[8,9]],
		"/[team]/admin/configuracion/subscriptions": [~66,[8,9]],
		"/[team]/admin/configuracion/subscriptions/[id]/update-seats": [~67,[8,9]],
		"/[team]/admin/configuracion/templates-docuseal": [~68,[8,9]],
		"/[team]/admin/configuracion/templates-docuseal/new": [~70,[8,9]],
		"/[team]/admin/configuracion/templates-docuseal/[templateId]": [~69,[8,9]],
		"/[team]/admin/equipo": [71,[8,9,10]],
		"/[team]/admin/equipo/invitar": [~73,[8,9,10]],
		"/[team]/admin/equipo/[userId]": [~72,[8,9,10]],
		"/[team]/admin/finances": [~74,[8,9]],
		"/[team]/admin/invalid-subscription": [~75],
		"/[team]/admin/pacientes": [~76,[8,9]],
		"/[team]/admin/pacientes/[patientId]": [~77,[8,9]],
		"/[team]/admin/servicios": [~78,[8,9]],
		"/[team]/admin/sessions": [~79,[8,9]],
		"/[team]/admin/therapies": [~80,[8,9]],
		"/[team]/admin/therapies/new": [~81,[8,9]],
		"/[team]/admin/waitlist": [~82,[8,9]],
		"/[publicPage]/claim": [~47,[7]],
		"/[publicPage]/create": [~48,[7]],
		"/[team]/profesional/(default)": [~83,[8,11]],
		"/[team]/profesional/configuracion": [~84,[8,11]],
		"/[team]/profesional/configuracion/encryption": [~85,[8,11]],
		"/[team]/profesional/configuracion/facturacion": [~86,[8,11]],
		"/[team]/profesional/configuracion/import": [87,[8,11]],
		"/[team]/profesional/configuracion/import/eholo": [~88,[8,11]],
		"/[team]/profesional/configuracion/import/google-calendar": [~89,[8,11]],
		"/[team]/profesional/configuracion/import/google-calendar/[calendarId]": [~90,[8,11]],
		"/[team]/profesional/configuracion/import/v2": [~91,[8,11]],
		"/[team]/profesional/dashboard": [~92,[8,11]],
		"/[team]/profesional/finances": [~93,[8,11]],
		"/[team]/profesional/finances/invoices/new": [~95,[8,11]],
		"/[team]/profesional/finances/invoices/[invoiceId]": [~94,[8,11]],
		"/[team]/profesional/invalid-license": [96],
		"/[team]/profesional/notificaciones": [97,[8,11]],
		"/[team]/profesional/patients": [~98,[8,11]],
		"/[team]/profesional/patients/archived": [~106,[8,11]],
		"/[team]/profesional/patients/new": [~107,[8,11]],
		"/[team]/profesional/patients/[patientId]": [~99,[8,11,12]],
		"/[team]/profesional/patients/[patientId]/documents": [~100,[8,11,12]],
		"/[team]/profesional/patients/[patientId]/documents/send": [~102,[8,11,12]],
		"/[team]/profesional/patients/[patientId]/documents/upload": [~103,[8,11,12]],
		"/[team]/profesional/patients/[patientId]/documents/[documentId]": [~101,[8,11,12]],
		"/[team]/profesional/patients/[patientId]/edit": [104,[8,11,12]],
		"/[team]/profesional/patients/[patientId]/events": [105,[8,11,12]],
		"/[team]/profesional/sessions": [~108,[8,11]],
		"/[team]/profesional/sessions/[sessionId]/edit": [~109,[8,11]],
		"/[team]/profesional/therapies": [~110,[8,11]],
		"/[team]/profesional/therapies/archived": [~120,[8,11]],
		"/[team]/profesional/therapies/new": [~121,[8,11]],
		"/[team]/profesional/therapies/[therapyId]": [~111,[8,11,13]],
		"/[team]/profesional/therapies/[therapyId]/documents": [112,[8,11,13]],
		"/[team]/profesional/therapies/[therapyId]/documents/send": [~114,[8,11,13]],
		"/[team]/profesional/therapies/[therapyId]/documents/upload": [~115,[8,11,13]],
		"/[team]/profesional/therapies/[therapyId]/documents/[documentId]": [~113,[8,11,13]],
		"/[team]/profesional/therapies/[therapyId]/events": [116,[8,11,13]],
		"/[team]/profesional/therapies/[therapyId]/notes/[noteId]": [~117,[8,11,13]],
		"/[team]/profesional/therapies/[therapyId]/settings": [~118,[8,11,13]],
		"/[team]/profesional/therapies/[therapyId]/work": [~119,[8,11,13]],
		"/[publicPage]/p": [49,[7]],
		"/[publicPage]/p/[professional]": [~50,[7]],
		"/[publicPage]/t": [51,[7]],
		"/[publicPage]/waitlist": [~52,[7]],
		"/[publicPage]/waitlist/success": [~53,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';